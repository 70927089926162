// 15. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-offset-horizontal: (
  small: 0.66rem,
  medium: 1rem,
);
$closebutton-offset-vertical: (
  small: 0.33em,
  medium: 0.5rem,
);
$closebutton-size: (
  small: 1.5em,
  medium: 2em,
);
$closebutton-lineheight: 1.1;
$closebutton-color: $black;
$closebutton-color-hover: $maroon;

// 23. Menu
// --------

$menu-margin: 0;
$menu-nested-margin: $global-menu-nested-margin;
$menu-items-padding: $global-menu-padding;
$menu-simple-margin: 1rem;
$menu-item-color-active: $white;
$menu-item-background-active: get-color(primary);
$menu-icon-spacing: 0.25rem;
$menu-state-back-compat: true;
$menu-centered-back-compat: true;
$menu-icons-back-compat: true;

// 25. Off-canvas
// --------------

$offcanvas-sizes: (
  small: 300px,
);
$offcanvas-vertical-sizes: (
  small: 300px,
);
$offcanvas-background: $light-white;;
$offcanvas-shadow: 0 0 10px rgba($black, 0.7);
$offcanvas-inner-shadow-size: 20px;
$offcanvas-inner-shadow-color: rgba($black, 0.25);
$offcanvas-overlay-zindex: 11;
$offcanvas-push-zindex: 12;
$offcanvas-overlap-zindex: 13;
$offcanvas-reveal-zindex: 12;
$offcanvas-transition-length: 0.5s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';


// 27. Pagination
// --------------

$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: $global-margin;
$pagination-item-color: $black;
$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-radius: $global-radius;
$pagination-item-background-hover: $light-gray;
$pagination-item-background-current: $primary-color;
$pagination-item-color-current: $white;
$pagination-item-color-disabled: $medium-gray;
$pagination-ellipsis-color: $black;
$pagination-mobile-items: false;
$pagination-mobile-current-item: false;
$pagination-arrows: true;
