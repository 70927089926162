button.close-button {
  color: $black;
  &:focus, &:active, &:hover {
    color: $maroon;
  }
}

.home {
  display: flex;
  margin:auto;
  margin-top: 4vh;
  margin-bottom: 2rem;
  justify-content: center;

  @include breakpoint(medium) {
    margin-top: 7vh;
    margin-bottom: 3rem;
  }

  &__left_side {
    background: $light-white;
    width: 7%;
    margin-left: -6px;
    -webkit-box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 1px 4px  rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
    &::after {
      -webkit-box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 4px 1px 4px  rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
    }
    @include breakpoint(medium) {
      width: 20%;
      margin-left: -68px;
    }
    @include breakpoint(large) {
      width: 10%;
    }


  }

  &__base {
    display: block;
    position: relative;
    width: 95%;
    padding-bottom: 2rem;
    border-right: .005rem solid #999;
    background: $light-white;
    -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 20px 0px 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 20px 0px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 20px 0px 20px 0px rgba(0, 0, 0, 0.2);

    @include breakpoint(medium) {
      width: 85%;
      padding-bottom: 4rem;
    }
    @media screen and (min-width: 40em) and (max-width: 51.875em) {
      width: 85%;
    }
  }

  &__image_link {
    display: block;
    margin: 2.5rem;
    cursor: pointer;
    text-align: center;
    @include breakpoint(medium) {
      margin: 5rem;
    }
    @media screen and (min-width: 40em) and (max-width: 51.875em) {
      margin: 4rem;
    }
  }
  &__title {
    text-align: center;
    font-size: 2.25rem;
    margin-top: 3rem;
    line-height: 2.5rem;
    &::first-letter, span {
      font-size: 3.25rem;
    }
    @include breakpoint(medium) {
      font-size: 5rem;
      margin-top: 4rem;
      line-height: 4.5rem;
      &::first-letter, span {
        font-size: 6rem;
      }
    }
    @media screen and (min-width: 40em) and (max-width: 51.875em) {
      font-size: 3rem;
      line-height: 3.25rem;
      &::first-letter, span {
        font-size: 4rem;
      }
    }
  }

  &__menu_title_list {
    padding-top: 2.5rem;
    width: 84%;
    margin:auto;
    padding-bottom: 3rem;
    @include breakpoint(medium down) {
      h2 {
        font-size: 2.25rem;
      }
    }
    @include breakpoint(medium) {
      padding-top: 5rem;
      width: 60%;
    }
    @media screen and (min-width: 40em) and (max-width: 51.875em) {
      h2 {
        font-size: 2rem;
      }
    }
  }

  &__menu_list {
    margin-top: 1rem;
    margin-left: 2.55rem;
      &__subtitle {
          font-style: italic;
          display: block;
          line-height: 1.3;
          padding-right: 1rem;
          width: 80%;
      }
      &__page {
        float: right;
        margin-top: -1.55rem;
      }
      li {
        font-size: 1.25rem;
        line-height: 1.3;
        padding-left: 0.425rem;
        margin-bottom: 1.1rem;

          // &:first-child a {
          //     pointer-events: none;
          //     cursor: default;
          //     text-decoration: none;
          //     color: $text-black;
          // }
      }

      @include breakpoint(medium) {
        margin-left: 2.6rem;
        &__subtitle {
          line-height: 1.5rem;
        }
        li {
          font-size: 1.625rem;
          margin-bottom: 1.250rem;
        }
      }
      @include breakpoint(large) {
        margin-left: 3rem;
      }
  }
}

.subpage {
  width: 96%;
  margin: 0 auto;
  margin-top: 4vh;
  margin-bottom: 3rem;

  @include breakpoint(medlarge) {
    margin-top: 7vh;
    margin-bottom: 4.5rem;
  }



  &__navmenu {
    .close-button {
      z-index: 4;
      margin-top: 22px;
      font-size: 1.67rem;
      &:hover {
        margin-top: 19px;
        font-size: 1.85rem;
      }
    }
    &_title {
      font-size: 1.7rem;
      margin: 1.5rem 1rem 0 1rem;
    }

    &_ol.menu {
      padding: 0;
      margin: 1rem 1.5rem 2rem 2.5rem;
      list-style: decimal;
      li {
          padding-left: 0;
        a {
          padding: 0.7rem 0 0.7rem .5rem;
          text-decoration: underline;
          line-height: 1.5rem;
          &:hover {
            text-decoration: none;
          }

          .home__menu_list__page {
            margin-top: -1.25rem;
          }

        }

      }

    }

  }





  &__grid_container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    // grid-template-areas:
    // "header"
    // "article"
    // "aside"
    // "footer";
    background: $light-white;
    -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 0px 0px 20px 0px rgba(0, 0, 0, 0.2);

    padding: 0 1.5rem;

    @include breakpoint(medlarge) {
      background: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    //   grid-template-areas:
    //   "... header"
    //   "article aside"
    //   "footer ...";
    }

      header {
        grid-column: 1 / 3;
        grid-row: 1;
        text-align: center;
        text-transform: uppercase;
        margin-top: 3rem;
        margin-bottom: 3.5rem;

        @include breakpoint(medlarge) {
          grid-column: 2 / 3;
          grid-row-start: 1;
          margin: 4rem 0 3.5rem 0;
          z-index: 3;
        }

      }

      footer {
        grid-column: 1 / 3;
        grid-row: 4;

        width: 100%;
        text-align: center;
        margin-top: 1rem;
        padding-bottom: 2rem;

        .subpage__page_number_hedera {
          display: block;

            div {
              display: inline-block;
              //margin: 0 2rem .5rem 0;
              height: 2rem;
              width: 4rem;
            }

            button.top-button {
              display: inline-block;
              vertical-align: sub;
              font-size: 2.5rem;
              color: $brown;
              cursor: pointer;
              height: 2rem;
              width: 2rem;
                &:hover {
                  color: maroon;
                  transform: rotate(-90deg);
                }
            }

        }

        @include breakpoint(medlarge) {
          grid-column: 1 / 2;
          grid-row: 3;
          z-index: 3;
          margin-top: -4rem;
          padding-bottom: 0;

        }

      }
  }

  .page_left, .page_right {
    width: 102%;
    background: $light-white;
    border-bottom: 1px sold gray;
    &:before {

      border-bottom: 1px sold gray;
    }
    &:after {

      border-bottom: 1px sold gray;
    }
  }

  .page_left {
    border-left: 1px sold gray;
    &:before {border-left: 1px sold gray;}
    &:after {border-left: 1px sold gray;}
  }

  .page_right {
    border-right: 1px sold gray;
    &:before {border-right: 1px sold gray;}
    &:after {border-right: 1px sold gray;}
  }


  article {
    grid-column: 1 / 3;
    grid-row: 2;

    font-size: 1.125;
    @include breakpoint(medlarge) {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      // z-index: 1;
    }
  }
  &__left {
    padding-bottom: 2rem;
    @include breakpoint(medlarge) {
      background: $light-white;
      -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), inset -20px 0px 20px 0px rgba(0, 0, 0, 0.2);
    }
    &_contents {

      p:nth-of-type(1)::first-letter {
        font-size: 2.5rem;
        line-height: 1.1;
        padding-right: 0;
      }

      @include breakpoint(medlarge) {
        margin: 4rem 4rem 6rem 6rem;

        p:nth-of-type(1)::first-letter {
          font-size: 3.125rem;
        }
      }

      @media screen and #{breakpoint(medlarge)} and #{breakpoint(large down)} {
        margin: 4rem 2rem 6rem 4rem;
      }

    }

    &_chapter {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 3.5rem;

      &__paragraph_space {
        padding-bottom: 2rem;
      }
    }

    h1 {
      text-align: center;
      font-size: 2rem;
      line-height: 1.1;
      margin-bottom: .2rem;
      @include breakpoint(medlarge) {
        font-size: 2.5rem;
        line-height: 1.1;
        margin-bottom: .25rem;
      }
    }
    h2 {
      text-align: center;
      font-style:italic;
      font-size: 1.75rem;
      line-height: 1.1;
      margin-bottom: 3rem;
      @include breakpoint(medlarge) {
        font-size: 2.125rem;
        line-height: 1.1;
        margin-bottom: 3.5rem;
      }
    }
  }

  aside {
    grid-column: 1 / 3;
    grid-row: 3;

    @include breakpoint(medlarge) {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      padding-top: 5.75rem;
    }

  }
  &__right {
    padding-bottom: 2rem;


    @include breakpoint(medlarge) {
      background: $light-white;
      border-right: .005rem solid #999;
      -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 20px 0px 20px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 20px 0px 20px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 20px 0px 20px 0px rgba(0, 0, 0, 0.2);
    }

    &_contents {
      text-align: center;

      &__image_space, img {
        padding-bottom: 2rem;

      }
      &#current_images {
        a {
          display: block;
          margin-bottom: 3rem;
        }
        img {
          padding-bottom: 2rem;
        }
      }

      @include breakpoint(medlarge) {
        margin: 4rem 5rem 4rem 4rem;
      }

      @media screen and #{breakpoint(medlarge)} and #{breakpoint(large down)} {
        margin: 4rem 3.5rem 4rem 2.5rem;
      }

      &_figcaption {
        margin: -1rem 0 2rem 0;
        font-style: italic;
      }

      &_list {
        margin-top: 2rem;
        margin-left: 3.5rem;
        list-style-type: circle;
      }

    }
  }


}

#second_image {
  margin-top: 2rem;
}

.subpage__left_side_image {
  display: block;
  padding-top: 2rem;
  text-align: center;
  margin-bottom: 3rem;
  img {
    padding-bottom: 2rem;
  }
}

#right_contents_text {
  text-align: left;
}

.copyright {
  font-style: italic;
  font-size:$small-font-size;
  margin-top: 4rem;
}

ol#sitemap_menu > li:last-child {
  display: none;
}

.menu_list__first_item {
  list-style: none;
  margin-left: 0;
}

// Parallax
.parallax {

	position: relative;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

    &_figure {
  		// The image used
  		background-image: url("/assets/img/mountainr_me_effect_Layer_smFigure.png");
  		// Set a specific height
  		height: 100vh;

  	}

  	&_ground {
  		// The image used
  		background-image: url("/assets/img/mountainr_me_effect_Layer_Ground.png");
  		// Set a specific height
  		height: 30vh;
  		// height: 500px;
  	}


  	&_water {
  		// The image used
  		background-image: url("/assets/img/mountainr_me_effect_Layer_Water.png");
  		// Set a specific height
  		height: 300vh;
  		// height: 31.25em;
  	}


  	&_mountains {
  		// The image used
  		background-image: url("/assets/img/mountainr_me_effect_Layer_Mountains.png");
  		// Set a specific height
  		height: 30vh;
  		//height: 500px;
  	}


  	&_sky {
  		// The image used
  		background-image: url("/assets/img/mountainr_me_effect_Layer_Sky.png");
  		// Set a specific height
  		height: 30vh;
  	}
}

.subpage__right_contents img.image_background {
  background: #EDEDED;
  padding: .25rem;
}

#bookmark_icon {
  color: $black;
  font-size: 1.7rem;
  transform: rotate(180deg);
}

.not_found {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2.6;
}
