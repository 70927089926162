@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'global/_typography';
@import 'global/_my_settings';
@import 'global/_book_app';
@import 'global/_accessibility_slider';


// Global styles
@include foundation-global-styles;
//@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
@include foundation-grid;
@include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
//@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
//@include foundation-pagination;
@include foundation-tooltip;

// Containers
//@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
//@include foundation-tabs;
//@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
//@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size-adjust: 0.403;
  font-size: 1.2rem;
  transition: background-color 0.45s ease, color 0.45s ease;

  .light_mode {
    background: $white;
    color: $black;
  }
  .dark_mode {
    background: $black;
    color: $white;
  }
}

.screen_reader_accessible {
  display: none;
  width:0;
  height: 0;
  color: $black;
  background: $white;
}

#skiptocontent a {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  background-color: #B22222;
  color: $white;
  z-index: 20;
  &:focus {
    position:relative;
    width:100vw;
    left:0;
    height:auto;
    padding: .5rem 1rem;
    display: block;
  }
  &:hover, &:active {
    color: #D6D6D6;
  }
}
